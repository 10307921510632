<template>
  <section class="main-section">
    <main-section-header
      title="도입설비 관리"
      subtitle="설비의 도입 유무를 확정하고, 설비관련 정보를 확인 및 수정할 수 있습니다."
    />
    <div class="container">
      <Tab :tabList="tabList" @setSelectedList="setSelectedList" />
      <div v-for="(list, index) in selectedList" :key="index">
        <approval-list :class="{ hide: list.name === '승인 대기' }" :approval-check="approvalCheck" />
        <waiting-approval-list
          :class="{ hide: list.name === '도입 승인' }"
          @waitingApproval="onWaitingApproval"
          @checkApprovalSuccess="onCheckApprovalSuccess"
        />
      </div>
    </div>
  </section>
</template>

<script>
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import Tab from '@/component/tab/Tab';
import ApprovalList from './approvalList/ApprovalList';
import WaitingApprovalList from './approvalList/WaitingApprovalList';

export default {
  name: 'FactoryEquipmentList',
  props: [],
  data() {
    return {
      approvalList: [
        {
          name: '도입 승인',
        },
      ],
      waitingApprovalList: [
        {
          name: '승인 대기',
        },
      ],
      tabList: [
        { name: '도입 승인', listLength: null },
        { name: '승인 대기', listLength: null },
      ],
      selectedList: [],
      approvalCheck: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.selectedList = this.approvalList;
  },
  watch: {},
  methods: {
    onWaitingApproval(equipmentList) {
      this.tabList[1].listLength = equipmentList;
    },
    setSelectedList(tabName) {
      if (tabName === '도입 승인') this.selectedList = this.approvalList;
      else this.selectedList = this.waitingApprovalList;
    },
    onCheckApprovalSuccess() {
      this.approvalCheck = !this.approvalCheck;
    },
  },
  components: { Tab, MainSectionHeader, ApprovalList, WaitingApprovalList },
};
</script>

<style scoped lang="scss">
@import 'FactoryEquipmentList';
</style>
