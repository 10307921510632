<template>
  <general-simple-popup v-if="isShow">
    <h2 slot="title" v-if="factoryEqpPopup[contentsKey].title">
      {{ $t(`message.${factoryEqpPopup[contentsKey].title}`) }}
    </h2>
    <p slot="contents" v-html="$t(`message.${factoryEqpPopup[contentsKey].description}`, namedFormatMsg)" />
    <div slot="contents" class="color-gray700">
      <ul class="register-list" v-if="isApprovePopup">
        <detail-list-row
          v-for="(info, index) in eqpInfo"
          :key="index"
          :is-register="true"
          :info="info"
          :inputKey="info.key"
          :input-value="eqpData[info.key]"
          :maxlength="info.maxlength"
          :placeholder="info.placeholder"
          :dropdown-list="dropdownList[info.key]"
          @saveChangeValue="onSaveInputValue"
          @setSelectedIdList="onSetSelectedIdList"
        >
          <span slot="label" class="label" :class="{ 'label-required': info.isRequired }">
            {{ info.label }}
            <template v-if="info.additionalInfo">
              <button class="tool-tip-button" @click.stop="toggleToolTip(info.key)">
                <vue-material-icon name="info" :size="12" />
                <span class="tool-tip" :class="{ 'tool-tip-show': isShowToolTip === info.key }">
                  {{ info.additionalInfo }}
                </span>
              </button>
            </template>
          </span>
        </detail-list-row>
      </ul>
    </div>
    <button-basic slot="button" color="gray-border-1" @click="onClose">취소</button-basic>
    <button-basic
      slot="button"
      color="primary"
      :disabled="!isRejectPopup && !isFilledAllRequiredField"
      @click="onConfirm"
    >
      {{ $t('message.confirm_btn') }}
    </button-basic>
  </general-simple-popup>
</template>

<script>
import GeneralSimplePopup from '@/component/popup/generalSimplePopup/GeneralSimplePopup';
import factoryEqpPopup from './FactoryEqpPopupData.json';
import { mapActions } from 'vuex';
import DetailListRow from '@/component/detailListRow/DetailListRow';

export default {
  name: 'FactoryEqpPopup',
  props: ['isShow', 'contentsKey', 'equipment'],
  data() {
    return {
      factoryEqpPopup: factoryEqpPopup,
      eqpInfo: [
        {
          key: 'equipmentName',
          label: '설비명',
          isRequired: true,
          editType: 'input',
          placeholder: '설비를 바로 인지할 수 있는 이름으로 입력하세요',
          additionalInfo: '웹이나 모바일에서 설비명으로 구분하여 정보를 보여줍니다.',
        },
        {
          key: 'mainManagerUserId',
          label: '점검 담당자 정',
          isRequired: true,
          editType: 'dropdown',
          placeholder: '점검 담당자 선택',
          maxCount: 1,
        },
        {
          key: 'subManagerUserId',
          label: '점검 담당자 부',
          isRequired: false,
          editType: 'dropdown',
          placeholder: '점검 담당자 선택',
          maxCount: 1,
        },
      ],
      eqpData: {
        equipmentName: '',
        mainManagerUserId: '',
        subManagerUserId: '',
      },
      dropdownList: {
        mainManagerUserId: [],
        subManagerUserId: [],
      },
      isShowToolTip: '',
      mainManagerUserList: [],
      subManagerUserList: [],
    };
  },
  computed: {
    namedFormatMsg() {
      return this.equipment ? { msg: this.equipment.manufacturerCompanyName, msg2: this.equipment.modelName } : null;
    },
    isApprovePopup() {
      return this.contentsKey === 'POST_FACTORY_EQP_APPROVE';
    },
    isRejectPopup() {
      return this.contentsKey === 'POST_FACTORY_EQP_REJECT';
    },
    isFilledAllRequiredField() {
      let isFilledAll = true;
      Object.values(this.eqpInfo).forEach((info) => {
        if (info.isRequired && !this.eqpData[info.key]) {
          isFilledAll = false;
        }
      });
      return isFilledAll;
    },
  },
  created() {
    this.getUserNameList();
    document.body.addEventListener('click', (e) => {
      e.stopPropagation();
      this.isShowToolTip = '';
    });
  },
  mounted() {},
  watch: {
    $route() {},
  },
  methods: {
    ...mapActions('factoryUsers', ['GET_FACTORY_USER_NAME']),
    async getUserNameList() {
      const userNameList = await this.GET_FACTORY_USER_NAME();
      this.mainManagerUserList = userNameList;
      this.subManagerUserList = userNameList;
      this.dropdownList.mainManagerUserId = this.mainManagerUserList;
      this.dropdownList.subManagerUserId = this.subManagerUserList;
    },
    onClose() {
      this.$emit('close');
    },
    onConfirm() {
      if (this.factoryEqpPopup[this.contentsKey]['isAlertType']) {
        this.onClose();
      } else {
        this.$emit('confirm', this.equipment.id, this.eqpData);
      }
    },
    onSaveInputValue(inputKey, changedValue) {
      this.eqpData[inputKey] = changedValue;
    },
    onSetSelectedIdList(contentIdList, selectedName, id) {
      switch (id) {
        case 'mainManagerUserId':
          this.eqpData.mainManagerUserId = contentIdList[0];
          this.dropdownList.subManagerUserId = this.filteredDropdownList(this.subManagerUserList, selectedName);
          break;
        case 'subManagerUserId':
          this.eqpData.subManagerUserId = contentIdList[0];
          this.dropdownList.mainManagerUserId = this.filteredDropdownList(this.mainManagerUserList, selectedName);
          break;
      }
    },
    filteredDropdownList(list, selectedName) {
      return list.filter((managerData) => managerData.name !== selectedName);
    },
    toggleToolTip(equipKey) {
      this.isShowToolTip = equipKey;
    },
  },
  components: { GeneralSimplePopup, DetailListRow },
};
</script>

<style scoped lang="scss">
@import 'FactoryEqpPopup';
</style>
