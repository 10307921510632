var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "general-simple-popup",
        [
          _vm.factoryEqpPopup[_vm.contentsKey].title
            ? _c("h2", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "message." + _vm.factoryEqpPopup[_vm.contentsKey].title
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("p", {
            attrs: { slot: "contents" },
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "message." + _vm.factoryEqpPopup[_vm.contentsKey].description,
                  _vm.namedFormatMsg
                )
              ),
            },
            slot: "contents",
          }),
          _c(
            "div",
            {
              staticClass: "color-gray700",
              attrs: { slot: "contents" },
              slot: "contents",
            },
            [
              _vm.isApprovePopup
                ? _c(
                    "ul",
                    { staticClass: "register-list" },
                    _vm._l(_vm.eqpInfo, function (info, index) {
                      return _c(
                        "detail-list-row",
                        {
                          key: index,
                          attrs: {
                            "is-register": true,
                            info: info,
                            inputKey: info.key,
                            "input-value": _vm.eqpData[info.key],
                            maxlength: info.maxlength,
                            placeholder: info.placeholder,
                            "dropdown-list": _vm.dropdownList[info.key],
                          },
                          on: {
                            saveChangeValue: _vm.onSaveInputValue,
                            setSelectedIdList: _vm.onSetSelectedIdList,
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "label",
                              class: { "label-required": info.isRequired },
                              attrs: { slot: "label" },
                              slot: "label",
                            },
                            [
                              _vm._v(" " + _vm._s(info.label) + " "),
                              info.additionalInfo
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "tool-tip-button",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.toggleToolTip(info.key)
                                          },
                                        },
                                      },
                                      [
                                        _c("vue-material-icon", {
                                          attrs: { name: "info", size: 12 },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "tool-tip",
                                            class: {
                                              "tool-tip-show":
                                                _vm.isShowToolTip === info.key,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(info.additionalInfo) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "button-basic",
            {
              attrs: { slot: "button", color: "gray-border-1" },
              on: { click: _vm.onClose },
              slot: "button",
            },
            [_vm._v("취소")]
          ),
          _c(
            "button-basic",
            {
              attrs: {
                slot: "button",
                color: "primary",
                disabled: !_vm.isRejectPopup && !_vm.isFilledAllRequiredField,
              },
              on: { click: _vm.onConfirm },
              slot: "button",
            },
            [_vm._v(" " + _vm._s(_vm.$t("message.confirm_btn")) + " ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }