var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-wrapper" }, [
    _c(
      "ul",
      _vm._l(_vm.tabList, function (tab, index) {
        return _c(
          "li",
          {
            key: index,
            class: { "tab-active": _vm.activeTab === tab.name },
            on: {
              click: function ($event) {
                return _vm.setActiveTabData(tab.name)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(tab.name) + " "),
            tab.listLength && tab.listLength < 10
              ? _c("span", [_vm._v(_vm._s(tab.listLength))])
              : tab.listLength && tab.listLength >= 10
              ? _c("span", { staticClass: "data-length-plus" }, [_vm._v("9+")])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }