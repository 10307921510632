var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section" },
    [
      _c("main-section-header", {
        attrs: {
          title: "도입설비 관리",
          subtitle:
            "설비의 도입 유무를 확정하고, 설비관련 정보를 확인 및 수정할 수 있습니다.",
        },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("Tab", {
            attrs: { tabList: _vm.tabList },
            on: { setSelectedList: _vm.setSelectedList },
          }),
          _vm._l(_vm.selectedList, function (list, index) {
            return _c(
              "div",
              { key: index },
              [
                _c("approval-list", {
                  class: { hide: list.name === "승인 대기" },
                  attrs: { "approval-check": _vm.approvalCheck },
                }),
                _c("waiting-approval-list", {
                  class: { hide: list.name === "도입 승인" },
                  on: {
                    waitingApproval: _vm.onWaitingApproval,
                    checkApprovalSuccess: _vm.onCheckApprovalSuccess,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }