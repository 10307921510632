var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "list",
        {
          staticClass: "waitingApproval",
          attrs: {
            list: _vm.equipmentList,
            headerList: _vm.equipmentHeaderList,
          },
        },
        [
          _vm._l(_vm.equipmentList, function (equipment, index) {
            return _c(
              "li",
              { key: index, attrs: { slot: "list-body" }, slot: "list-body" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatEQPCode")({
                        mEqpId: equipment["mequipmentId"],
                        eqpId: equipment["id"],
                      })
                    )
                  ),
                ]),
                _c("span", [_vm._v(_vm._s(equipment.manufacturerCompanyName))]),
                _c("span", [_vm._v(_vm._s(equipment.modelName))]),
                _c(
                  "span",
                  [
                    _c(
                      "button-basic",
                      {
                        attrs: { color: "dark", size: "ss" },
                        on: {
                          click: function ($event) {
                            return _vm.onApprove(equipment)
                          },
                        },
                      },
                      [_vm._v("승인")]
                    ),
                    _c(
                      "button-basic",
                      {
                        attrs: { color: "red", size: "ss" },
                        on: {
                          click: function ($event) {
                            return _vm.onReject(equipment)
                          },
                        },
                      },
                      [_vm._v("반려")]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          _c(
            "p",
            {
              attrs: { slot: "no-list-description" },
              slot: "no-list-description",
            },
            [_vm._v("등록된 정보가 없습니다.")]
          ),
        ],
        2
      ),
      _c("factory-eqp-popup", {
        attrs: {
          isShow: _vm.showedPopupName === "FactoryEqpPopup",
          contentsKey: _vm.popupContentsKey,
          equipment: _vm.equipment,
        },
        on: { close: _vm.togglePopup, confirm: _vm.onConfirmIntdEqpPopup },
      }),
      _c("confirm-popup", {
        attrs: {
          "is-show": _vm.showedPopupName === "ConfirmPopup",
          "contents-key": _vm.popupContentsKey,
        },
        on: { close: _vm.onCloseConfirmPopup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }