<template>
  <div class="tab-wrapper">
    <ul>
      <li
        v-for="(tab, index) in tabList"
        :key="index"
        @click="setActiveTabData(tab.name)"
        :class="{ 'tab-active': activeTab === tab.name }"
      >
        {{ tab.name }}
        <span v-if="tab.listLength && tab.listLength < 10">{{ tab.listLength }}</span>
        <span v-else-if="tab.listLength && tab.listLength >= 10" class="data-length-plus">9+</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: ['tabList', 'waitingListCount', 'selectedTabIndex'],
  data() {
    return {
      activeTab: '',
    };
  },
  computed: {},
  created() {
    this.activeTab = this.tabList[Number(this.selectedTabIndex) || 0].name;
  },
  mounted() {},
  watch: {},
  methods: {
    setActiveTabData(tabName) {
      this.activeTab = tabName;
      this.$emit('setSelectedList', tabName);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'Tab';
</style>
