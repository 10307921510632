<template>
  <div>
    <list-utility-menu
      :hasSearchInput="true"
      placeholder="설비명으로 검색하세요"
      @input="onChangeKeyword"
      @button="onMoveSearchedPage(1)"
      @keyupenter="onMoveSearchedPage(1)"
      :value="keyword"
    />
    <list
      class="approval"
      @sort="onClickSort"
      :list="equipmentList"
      :headerList="equipmentHeaderList"
      is-possible-hover-list="isPossibleHoverList"
    >
      <li
        slot="list-body"
        v-for="(equipment, index) in equipmentList"
        :key="index"
        @click="onMoveEquipmentDetail(equipment.id)"
      >
        <span>{{ { mEqpId: equipment['mequipmentId'], eqpId: equipment['id'] } | formatEQPCode }}</span>
        <span>{{ equipment.equipmentName }}</span>
        <span>
          <span v-if="equipment.factoryEquipmentManagers.length === 1">
            {{ getManagerByPriority(equipment.factoryEquipmentManagers, 1).userName }} / -
          </span>
          <span v-else>
            {{ getManagerByPriority(equipment.factoryEquipmentManagers, 1).userName }} /
            {{ getManagerByPriority(equipment.factoryEquipmentManagers, 2).userName }}
          </span>
        </span>
        <span>{{ equipment.manufacturerCompanyName }}</span>
        <span>{{ equipment.modelName }}</span>
      </li>
      <p slot="no-list-description">{{ noListDescription }}</p>
    </list>
    <pagination
      v-if="totalPageCount > 1"
      :page="page"
      :totalPageCount="totalPageCount"
      :pageSize="pageSize"
      @setPageNum="setPageNum"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ListUtilityMenu from '@/component/list/ListUtilityMenu.vue';
import List from '@/component/list/List';
import ListHeaderData from '../ListHeaderData.json';
import Pagination from '@/component/pagination/Pagination';
import noListDescription from '@/asset/js/noListDescription';
import getManagerName from '@/asset/js/getManagerName';

export default {
  name: 'ApprovalList',
  props: ['approvalCheck'],
  data() {
    return {
      page: 1,
      totalPageCount: 0,
      pageSize: 20,
      sortOrder: '',
      keyword: '',
      equipmentHeaderList: ListHeaderData.approval,
      equipmentList: null,
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    noListDescription() {
      return noListDescription(this.isRequestedDataWithKeyword);
    },
  },
  created() {
    this.getFactoryApprovalEqps();
  },
  mounted() {},
  watch: {
    $route() {
      this.getFactoryApprovalEqps();
    },
    approvalCheck() {
      this.getFactoryApprovalEqps();
      this.$emit('checkApprovalSuccess');
    },
  },
  methods: {
    ...mapActions('factoryEquipments', ['GET_FACTORY_EQPS']),
    onClickSort(sort) {
      if (this.sortOrder) {
        this.sortOrder = `${this.sortOrder.indexOf('-') === 0 ? '' : '-'}${sort}`;
      } else {
        this.sortOrder = sort;
      }
      this.onMoveSearchedPage();
    },
    setQueryParams() {
      this.keyword = this.$route.query.keyword || '';
      this.filter = this.$route.query.filter || 'All';
      this.sortOrder = this.$route.query.sortOrder || '';
      this.page = this.$route.query.page || 1;
    },
    onMoveEquipmentDetail(equipmentId) {
      this.$router.push(`/factory-equipments/${equipmentId}`).catch((err) => err);
    },
    onMoveSearchedPage(page) {
      this.$router
        .push({
          query: {
            sortOrder: this.sortOrder || undefined,
            page: page || this.page || undefined,
            size: this.pageSize.toString() || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    setPageNum(page) {
      this.page = page;
      this.onMoveSearchedPage();
    },
    async getFactoryApprovalEqps() {
      this.setQueryParams();
      this.equipmentList = null;
      this.totalPageCount = 0;
      this.isRequestedDataWithKeyword = !!this.keyword;
      const factoryEquipments = await this.GET_FACTORY_EQPS([this.sortOrder, this.page, this.pageSize, this.keyword]);
      this.equipmentList = factoryEquipments.result;
      this.totalPageCount = factoryEquipments['metaData'].totalPageCount;
    },
    getManagerByPriority(managers, priority) {
      return getManagerName(managers, priority);
    },
  },
  components: { ListUtilityMenu, List, Pagination },
};
</script>
<style scoped lang="scss"></style>
