<template>
  <div>
    <list class="waitingApproval" :list="equipmentList" :headerList="equipmentHeaderList">
      <li slot="list-body" v-for="(equipment, index) in equipmentList" :key="index">
        <span>{{ { mEqpId: equipment['mequipmentId'], eqpId: equipment['id'] } | formatEQPCode }}</span>
        <span>{{ equipment.manufacturerCompanyName }}</span>
        <span>{{ equipment.modelName }}</span>
        <span>
          <button-basic color="dark" size="ss" @click="onApprove(equipment)">승인</button-basic>
          <button-basic color="red" size="ss" @click="onReject(equipment)">반려</button-basic>
        </span>
      </li>
      <p slot="no-list-description">등록된 정보가 없습니다.</p>
    </list>
    <factory-eqp-popup
      :isShow="showedPopupName === 'FactoryEqpPopup'"
      :contentsKey="popupContentsKey"
      @close="togglePopup"
      @confirm="onConfirmIntdEqpPopup"
      :equipment="equipment"
    />
    <confirm-popup
      :is-show="showedPopupName === 'ConfirmPopup'"
      :contents-key="popupContentsKey"
      @close="onCloseConfirmPopup"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import List from '@/component/list/List';
import ListHeaderData from '../ListHeaderData.json';
import FactoryEqpPopup from '@/component/popup/factoryEqpPopup/FactoryEqpPopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';

export default {
  name: 'WaitingApprovalList',
  props: [],
  data() {
    return {
      equipmentHeaderList: ListHeaderData.waitingApproval,
      equipmentList: null,
      showedPopupName: '',
      popupContentsKey: '',
      equipment: '',
    };
  },
  computed: {},
  created() {
    this.getFactoryWaitingEqps();
  },
  mounted() {},
  watch: {
    $route() {
      this.getFactoryWaitingEqps();
    },
  },
  methods: {
    ...mapActions('factoryEquipments', [
      'GET_FACTORY_EQPS_WAIT',
      'POST_FACTORY_EQP_REJECT',
      'POST_FACTORY_EQP_APPROVE',
    ]),
    async onCloseConfirmPopup() {
      switch (this.popupContentsKey) {
        case 'POST_FACTORY_EQP_REJECT_CONFIRM':
        case 'POST_FACTORY_EQP_APPROVE_CONFIRM':
          await this.getFactoryWaitingEqps();
          this.$emit('checkApprovalSuccess');
          this.togglePopup();
          break;
        default:
          this.togglePopup();
          break;
      }
    },
    onMoveEquipmentDetail(equipmentId) {
      this.$router.push(`factory-equipments/${equipmentId}`).catch((err) => err);
    },
    async getFactoryWaitingEqps() {
      this.equipmentList = await this.GET_FACTORY_EQPS_WAIT();
      this.$emit('waitingApproval', this.equipmentList.length);
    },
    togglePopup(popupName, popupContentsKey, equipment) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
      this.equipment = equipment || '';
    },
    async onConfirmIntdEqpPopup(eqpId, eqpData) {
      switch (this.popupContentsKey) {
        case 'POST_FACTORY_EQP_REJECT':
          await this.POST_FACTORY_EQP_REJECT(eqpId);
          this.togglePopup('ConfirmPopup', 'POST_FACTORY_EQP_REJECT_CONFIRM');
          break;
        case 'POST_FACTORY_EQP_APPROVE':
          await this.POST_FACTORY_EQP_APPROVE([eqpId, eqpData]);
          this.togglePopup('ConfirmPopup', 'POST_FACTORY_EQP_APPROVE_CONFIRM');
          break;
      }
    },
    onReject(equipment) {
      this.togglePopup('FactoryEqpPopup', 'POST_FACTORY_EQP_REJECT', equipment);
    },
    onApprove(equipment) {
      this.togglePopup('FactoryEqpPopup', 'POST_FACTORY_EQP_APPROVE', equipment);
    },
  },
  components: { ConfirmPopup, List, FactoryEqpPopup },
};
</script>
<style scoped lang="scss"></style>
